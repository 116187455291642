import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA6XNYZDf9b0HXkR3LS7ayemWxhNYDRzNc",
  authDomain: "b4tech-dc946.firebaseapp.com",
  projectId: "b4tech-dc946",
  storageBucket: "b4tech-dc946.appspot.com",
  messagingSenderId: "1007504070326",
  appId: "1:1007504070326:web:56f51ba3704a038a427817",
  measurementId: "G-XR76J1E5P9",
};

initializeApp(firebaseConfig);

export const provider = new GoogleAuthProvider();
export const auth = getAuth();
export const db = getFirestore();
