import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import Card from "./Card";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [load, setLoad] = useState(true);
  useEffect(async () => {
    try {
      let l = [];
      const docs = await getDocs(collection(db, "courses"));
      docs.forEach((doc) => l.push(doc.data()));
      setCourses(l);
      setLoad(false);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }, []);

  return (
    <div className="font-inter container p-4 md:w-3/4 mx-auto">
      <h1 className="font-bold text-3xl text-blue-700">Courses available</h1>
      {load ? (
        <p>Loading...</p>
      ) : (
        <div className="grid md:grid-cols-3  gap-6 my-4">
          {courses.map((course, index) => (
            <Card key={index} course={course} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Courses;
