import { useHistory } from "react-router-dom";

const Card = ({ course }) => {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        history.push({
          pathname: `/courses/details/${course.id}`,
          state: {
            name: course.name,
            id: course.id,
            status: course.status,
          },
        });
      }}
      className="rounded-md p-3 border-2 hover:cursor-pointer"
    >
      <img src={course.banner} />
      <p className="font-bold font-inter text-blue-700 py-4 text-2xl">
        {course.name}
      </p>
    </div>
  );
};

export default Card;
