import React, { useContext, useState, useEffect } from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "./firebase";
import Loading from "./components/Loading";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

// export const login = () => {
//   return signInWithPopup(auth, provider);
// };

// export const logout = () => {
//   return auth.signOut().catch((e) => console.log(e));
// };

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [currentUser]);

  const value = {
    currentUser,
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
