import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Courses from "./components/Courses";
import { lazy, Suspense } from "react";
import Loading from "./components/Loading";
const Home = lazy(() => import("./components/Home"));
const Private = lazy(() => import("./components/Private"));
const Header = lazy(() => import("./components/Header"));
const Details = lazy(() => import("./components/Details"));
const CourseRegister = lazy(() => import("./components/Form"));

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <AuthProvider>
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Private path="/courses" exact component={Courses} />
            <Private path="/courses" exact component={Courses} />
            <Private path="/courses/:id" exact component={CourseRegister} />
            <Private path="/courses/details/:id" component={Details} />
          </Switch>
        </Router>
      </AuthProvider>
    </Suspense>
  );
}

export default App;
